import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";
import { error } from "components/Toast/Toast";

// material ui
import { Grid, Button } from "@material-ui/core";

// component
import InputForm from "components/Forms/Input";
import BankInput from "components/Forms/Bank";
import SelectInput from "components/Forms/SelectInput";
import LoadingOverLay from "components/Loading/LoadingOverLay";

// styles
import styles from "../Index.module.scss";

const Form = ({ slug, setComp }) => {
  const { t } = useTranslation();

  const [enable, setEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const [name, setName] = useState("");
  const [kana, setKana] = useState("");
  const [bankNum, setBankNum] = useState("");
  const [branchNum, setBranchNum] = useState("");
  const [bankType, setBankType] = useState("");
  const [accountNum, setAccountNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (
      !name.trim() ||
      !kana.trim() ||
      !bankNum.trim() ||
      !branchNum.trim() ||
      !bankType.trim() ||
      !accountNum.trim() ||
      accountNum.length !== 7
    ) {
      setEnable(false);
      return;
    }
    setEnable(true);
  }, [name, kana, bankNum, branchNum, bankType, accountNum]);

  useEffect(() => {
    if (accountNum.length > 0 && accountNum.length !== 7) {
      setErrorMessage(t("error_account"));
    } else {
      setErrorMessage("");
    }
  }, [accountNum]);

  const params = {
    transfer_type: "jp",
    name_kana: kana,
    name_kanji: name,
    bank_code: bankNum,
    branch_code: branchNum,
    account_number: accountNum,
    type: bankType,
  };

  const submit = () => {
    setDisable(true);
    setLoading(true);
    rest(`Money/Bank/Withdraw/${slug}:input`, "POST", params)
      .then(() => {
        setLoading(false);
        setDisable(false);
        setComp(true);
      })
      .catch((err) => {
        setLoading(false);
        setDisable(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={styles["error-box"]}>
            <p>{errorMessage}</p>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <InputForm
            label={t("common_refund_form_label_name")}
            value={name}
            name="name"
            handleChange={(e) => {
              setName(e.target.value);
            }}
            disabled={disable}
            helperText={t("help_input_kana")}
          />
        </Grid>
        <Grid item xs={12}>
          <InputForm
            label={t("common_refund_form_label_name_kana")}
            value={kana}
            name="kana"
            handleChange={(e) => {
              setKana(e.target.value);
            }}
            disabled={disable}
            helperText={t("common_refund_form_label_name_kana_help")}
          />
        </Grid>
        <Grid item xs={12}>
          <BankInput
            setFunc={setBankNum}
            name="bank"
            label={t("common_refund_from_label_bank")}
            helperText={t("common_refund_from_label_bank_helper")}
          />
        </Grid>
        <Grid item xs={12}>
          <BankInput
            setFunc={setBranchNum}
            name="bank"
            label={t("common_refund_from_label_branch")}
            helperText={t("common_refund_from_label_bank_helper")}
            disabled={bankNum === "" || disable}
            bankNum={bankNum}
            lookup="lookup_branch"
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label={t("common_refund_from_label_type")}
            value={bankType}
            name="type"
            type="number"
            array={[
              { label: t("bank-type-normal"), val: "normal" },
              { label: t("bank-type-current"), val: "current" },
              { label: t("bank-type-savings"), val: "savings" },
            ]}
            handleChange={(e) => {
              setBankType(e.target.value);
            }}
            disabled={disable}
          />
        </Grid>
        <Grid item xs={12}>
          <InputForm
            label={t("common_refund_from_label_account")}
            helperText={t("help_account")}
            value={accountNum}
            name="account"
            handleChange={(e) => {
              setAccountNumber(e.target.value);
            }}
            error={accountNum.length > 0 && accountNum.length !== 7}
            errorText={errorMessage}
            disabled={disable}
          />
        </Grid>
        <Grid item xs={12} className={styles["ta-c"]}>
          <Button
            onClick={() => {
              submit();
            }}
            variant="outlined"
            disabled={!enable}
          >
            {t("form_submit")}
          </Button>
        </Grid>
      </Grid>
      {loading && <LoadingOverLay />}
    </>
  );
};

export default Form;
