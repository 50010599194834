import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Material Ui
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { Theme } from "assets/jss/Theme";
import { StylesProvider } from "@material-ui/core/styles";

// layout
import Layout from "components/Layout/Layout";

// page
import Index from "views/Index/Index";

// util

// mail

const Mail = () => {
  return <Switch></Switch>;
};

const Pages = () => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <StylesProvider injectFirst>
        <Layout>
          <Switch>
            <Route exact path="/:slug">
              <Index />
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Layout>
      </StylesProvider>
    </ThemeProvider>
  );
};

const Core = () => {
  return (
    <Switch>
      <Route path="/mail">
        <Mail />
      </Route>
      <Route>
        <Pages />
      </Route>
    </Switch>
  );
};

const Routing = () => {
  return <Core />;
};

export default Routing;
