import { createTheme } from "@material-ui/core/styles";

export const Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1080,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#ccc",
    },
    secondary: {
      main: "#000",
    },
    background: {
      default: "#fff",
    },
    error: {
      main: "#d32f2f",
      contrastText: "#fff",
    },
  },
  spacing: 4,
  typography: {
    fontFamily: [
      "YakuHanJP",
      "Helvetica Neue",
      "Arial",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "sans-serif",
    ].join(","),
  },
});
