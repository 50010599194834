import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// material ui

// conponents
import Helmet from "components/Helmet/Helmet";
import AppBarElemtnt from "./AppBar";
import Toast from "components/Toast/Toast";

// syyles
import styles from "./Layout.module.scss";

const Layout = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Helmet>
        <title>{t("common_refund_title")}</title>
      </Helmet>
      <div>
        <AppBarElemtnt />
        <main className={styles["layout-main"]}>{children}</main>
      </div>
      <Toast duration={5000} />
    </div>
  );
};

export default Layout;
