import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useParams } from "react-router-dom";

// material ui
import { Typography } from "@material-ui/core";

// component
import InfoCard from "./section/InfoCard";
import Form from "./section/Form";

// styles
import styles from "./Index.module.scss";

const Index = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [comp, setComp] = useState(false);

  const [withdraw] = useRest(`Money/Bank/Withdraw/${slug}`, true);

  return (
    <>
      {withdraw !== null && (
        <>
          <InfoCard withdraw={withdraw} />
          {withdraw.data.Status !== "input" && (
            <>
              <Typography
                className={styles["title"]}
                variant="h5"
                component="div"
                dangerouslySetInnerHTML={{ __html: t("success_comp_status") }}
              />
            </>
          )}
          {withdraw.data.Status === "input" && (
            <>
              {comp && (
                <>
                  <Typography
                    className={styles["title"]}
                    variant="h5"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: t("success_comp") }}
                  />
                  <Typography
                    className={styles["title"]}
                    variant="body"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: t("success_text") }}
                  />
                </>
              )}
              {!comp && (
                <>
                  <p className={styles["desc-text"]}>
                    {t("common_refund_desc_text", {
                      name: withdraw.data.Meta.name,
                    })}
                  </p>
                  <Typography
                    className={styles["title"]}
                    variant="h5"
                    component="div"
                  >
                    {t("common_refund_form_title")}
                  </Typography>
                  <Form withdraw={withdraw} slug={slug} setComp={setComp} />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Index;
