import React from "react";
import { useTranslation } from "react-i18next";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  wrapper: {
    justifyContent: "center",
  },
  header: {
    backgroundColor: "white",
    boxShadow: "none",
    borderBottom: "1px solid #ccc",
  },
}));

const AppBarElemtnt = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar className={classes.header} position="static">
      <Toolbar className={classes.wrapper}>
        <Typography variant="h6" component="div">
          {t("common_refund_title")}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarElemtnt;
