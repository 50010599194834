import React from "react";

// material ui
import {
  FormControl,
  Input,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";

// style
import styles from "./Form.module.scss";

const InputForm = ({
  name = null,
  label = "default",
  handleChange = null,
  value = "",
  placeholder = "",
  helperText = "",
  disabled = false,
  type = "text",
  required = false,
  error = false,
  errorText = "",
  max = null,
}) => {
  let params = {
    className: styles["form-input"],
    id: `${name}-helper`,
    value: value,
    onChange: (e) => {
      handleChange(e);
    },
    name: name,
    "aria-describedby": `${name}-helper-text`,
    placeholder: placeholder,
    disabled: disabled,
    type: type,
  };

  if (max !== null) {
    params.inputProps = {
      maxLength: 20,
    };
  }

  return (
    <FormControl
      required={required}
      fullWidth
      className={styles["form-control"]}
      error={error}
    >
      <InputLabel
        className={styles["form-label"]}
        shrink={true}
        disableAnimation={true}
        htmlFor={`${name}-helper`}
      >
        {label}
      </InputLabel>
      <Input {...params} />
      {error && (
        <FormHelperText className={styles["form-error-text"]}>
          {errorText}
        </FormHelperText>
      )}
      {helperText.length !== 0 && (
        <FormHelperText
          className={styles["form-helper-text"]}
          id={`${name}-helper-text`}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputForm;
