import React from "react";
import { useTranslation } from "react-i18next";

// material ui
import { Card, CardContent, Typography } from "@material-ui/core";

// component

// styles
import styles from "../Index.module.scss";

const InfoCard = ({ withdraw }) => {
  const { t } = useTranslation();

  return (
    <Card className={styles["card"]}>
      <CardContent>
        <Typography
          className={styles["card-title"]}
          variant="h5"
          component="div"
        >
          {t("common_refund_info_title")}
        </Typography>
        <dl className={styles["card-info"]}>
          <div className={styles["card-info-group"]}>
            <dt>{t("common_refund_info_name")}</dt>
            <dd>
              {t("common_refund_info_name_text", {
                name: withdraw.data.Meta.name,
              })}
            </dd>
          </div>
          <div className={styles["card-info-group"]}>
            <dt>{t("common_refund_info_order")}</dt>
            <dd>{withdraw.data.Label}</dd>
          </div>
          <div className={styles["card-info-group"]}>
            <dt>{t("common_refund_info_amout")}</dt>
            <dd>{withdraw.data.Amount.display}</dd>
          </div>
        </dl>
      </CardContent>
    </Card>
  );
};

export default InfoCard;
