import React, { useEffect, useState } from "react";
import { getLocale, rest } from "@karpeleslab/klbfw";
import { error } from "components/Toast/Toast";

// material ui
import {
  FormControl,
  Input,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// style
import styles from "./Form.module.scss";
import clsx from "clsx";

const BankInput = ({
  name = null,
  label = "default",
  placeholder = "",
  helperText = "",
  disabled = false,
  type = "text",
  required = false,
  setFunc = null,
  lookup = "lookup_bank",
  bankNum = null,
}) => {
  const [bankList, setBankList] = useState([]);
  const [bankError, setBankError] = useState(false);
  const [inputValue, setInputValue] = useState("");

  let params = {
    className: clsx(styles["form-input"], {
      [styles["form-input-disable"]]: disabled,
    }),
    name: name,
    "aria-describedby": `${name}-helper-text`,
    placeholder: placeholder,
    disabled: disabled,
    type: type,
    fullWidth: true,
    error: bankError,
  };

  const BankSearch = (e) => {
    let params = {
      label: e.target.value,
    };

    setBankError(false);

    if (lookup === "lookup_branch") {
      params.bank_id = bankNum;
    }

    rest(`Money/Japan:${lookup}`, "POST", params)
      .then((data) => {
        setBankList(data.data);
        if (data.data.length === 1) {
          setBankError(false);
          setFunc(
            lookup !== "lookup_branch"
              ? data.data[0].Money_Japan_Bank__
              : data.data[0].Branch_Id
          );
        } else {
          setFunc("");
          setBankError(true);
        }
      })
      .catch((err) => {
        setBankError(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (lookup === "lookup_branch") {
      setBankList([]);
      setInputValue("");
      setFunc("");
    }
  }, [bankNum]);

  return (
    <Autocomplete
      freeSolo
      fullWidth
      options={bankList.map((option) => {
        if (getLocale() === "ja-JP") {
          return option.Name;
        } else {
          return option.Name_Alpha;
        }
      })}
      filterOptions={(x) => x}
      onSelect={(e) => {
        BankSearch(e);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      inputValue={inputValue}
      disabled={disabled}
      renderInput={(inputParams) => {
        const joinProps = { ...inputParams.inputProps, ...params };
        return (
          <FormControl
            required={required}
            fullWidth
            className={styles["form-control"]}
            error={bankError}
            disabled={disabled}
          >
            <InputLabel
              className={clsx(styles["form-label"], {
                [styles["form-label-disable"]]: disabled,
              })}
              shrink={true}
              disableAnimation={true}
              htmlFor={`${name}-helper`}
              disabled={disabled}
            >
              {label}
            </InputLabel>
            <div
              className={styles["form-bank-wrapper"]}
              ref={inputParams.InputProps.ref}
            >
              <Input {...joinProps} />
            </div>
            {helperText.length !== 0 && (
              <FormHelperText
                className={clsx(styles["form-helper-text"], {
                  [styles["form-helper-text-disable"]]: disabled,
                })}
                id={`${name}-helper-text`}
                disabled={disabled}
              >
                {helperText}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default BankInput;
